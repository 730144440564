
import { Component, Prop, Vue } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import {
  Chart,
  BarController,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import '../Charts.scss';

Chart.register(BarController, Legend, Tooltip, CategoryScale, LinearScale, BarElement);

const COLORS = [
  '#dd9f9f',
  '#ddb99f',
  '#ddd39f',
  '#cedd9f',
  '#b4dd9f',
  '#9fdda4',
  '#9fddbe',
  '#9fddd8',
  '#9fc9dd',
  '#9fafdd',
  '#aa9fdd',
  '#c39fdd',
  '#dd9fdd',
  '#dd9fc3',
];

@Component({})
export default class SelectManyChart extends Vue {
  @Prop() chartData!: { selections?: string[] }[];

  @Prop() options!: string[];

  public renderChart!: (chartData: any, options: any) => void;

  id = uuid();

  chart?: Chart;

  mounted() {
    const labels = this.options;
    const labelKeys = labels.reduce((a, b, c) => {
      a[b] = c;
      return a;
    }, {} as Record<string, number>);

    if (document.getElementById(this.id)) {
      const votes = this.chartData
        .map(a => (a.selections ?? []).map(b => labelKeys[b]))
        .reduce((a, b) => a.concat(b), [] as number[]);

      const data = labels.reduce((a, _b, c) => {
        let voteCount = 0;
        votes.forEach(vote => {
          if (vote === c) voteCount += 1;
        });
        a.push(voteCount);
        return a;
      }, [] as number[]);

      this.chart = new Chart(document.getElementById(this.id) as HTMLCanvasElement, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: 'All Responses',
              data,
              backgroundColor: [...COLORS.sort(() => Math.random() - 0.5)],
            },
          ],
        },
        options: {
          indexAxis: 'y',
          // Elements options apply to all of the options unless overridden in a dataset
          // In this case, we are setting the border of each horizontal bar to be 2px wide
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  }
}
