
import { Component, Prop, Vue } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import { Chart, ArcElement, DoughnutController, Legend, Tooltip } from 'chart.js';
import '../Charts.scss';

Chart.register(ArcElement, DoughnutController, Legend, Tooltip);

const COLORS = [
  '#dd9f9f',
  '#ddb99f',
  '#ddd39f',
  '#cedd9f',
  '#b4dd9f',
  '#9fdda4',
  '#9fddbe',
  '#9fddd8',
  '#9fc9dd',
  '#9fafdd',
  '#aa9fdd',
  '#c39fdd',
  '#dd9fdd',
  '#dd9fc3',
];

@Component({})
export default class TrueFalseChart extends Vue {
  @Prop() chartData!: { verdict?: boolean }[];

  @Prop() options!: string[];

  public renderChart!: (chartData: any, options: any) => void;

  id = uuid();

  chart?: Chart;

  mounted() {
    const labels = ['Yes', 'No'];

    if (document.getElementById(this.id)) {
      this.chart = new Chart(document.getElementById(this.id) as HTMLCanvasElement, {
        type: 'doughnut',
        data: {
          labels,
          datasets: [
            {
              label: 'All Responses',
              data: this.chartData.reduce(
                (a, b) => {
                  a[b.verdict ? 0 : 1] += 1;
                  return a;
                },
                [0, 0] as number[],
              ),
              backgroundColor: [...COLORS.sort(() => Math.random() - 0.5)],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
          },
        },
      });
    }
  }
}
